
<template>
  <div class="work-order-wrapper flex" v-if="$route.path==='/tourModule/report/workorder'">
    <d-search :searchData="searchData" @searchEvent="searchList" @exportEvent="exportList" @resetEvent="reset"></d-search>
    <div class="inline-flex contentBox">
      <d-table :tableData="tableData" :columnData="columnData" class="contentTable" @viewEvent="showDetail">
        <el-table-column label="评价" slot="evaluate" prop="evaluate">
          <template slot-scope="{row}">
            {{row.scoreAttitude+row.scoreSolveQuality+row.scoreSolveSpeed}}
          </template>
        </el-table-column>
      </d-table>
    </div>
    <d-paging :pager="pager" @change="searchList"></d-paging>
  </div>
  <div v-else>
    <router-view></router-view>
  </div>
</template>

<script>
import DSearch from "@/components/d-search";
import DTable from "@/components/d-table";
import DPaging from "@/components/d-paging";
import {onDownload} from "@/utils/utils";
export default {
  name: "workOrderStatistic",
  components: {DPaging, DTable, DSearch},
  data() {
    return {
      searchData: {
        searchItem: [
          {label:'工单号',value:'orderNo',type:'commonInput'},
          {label:'工单解决时间',value:'time',type:'commonDatetime'},
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true,
          exportBtn:true
        },
        params: {
          orderNo:'',
          time:['','']
        }
      },
      tableData: [],
      columnData: [
        {label: '工单号', prop: 'orderNo'},
        {label: '巡场区域', prop: 'areaName'},
        {label: '发起部门', prop: 'createUserAndDeptName'},
        {label: '处理部门/人', prop: 'handleUserAndDeptName'},
        {label: '问题类型', prop: 'typeName'},
        {label: '巡场报修时间', prop: 'createTime'},
        {label: '状态', prop: 'statusName'},
        {label: '工单解决时间', prop: 'finishTime'},
        {label: '评价', prop: 'evaluate',slot:true},
        {label: '工单耗时', prop: 'elapsedTime'},
        {label: '操作', prop: 'operate',type: {
            viewBtn:true
          }
        },
      ],
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      }
    }
  },
  mounted(){
    this.searchList()
  },
  methods:{
    _getParams(){
      return {
        startTime:(this.searchData.params.time&&this.searchData.params.time[0])||'',
        endTime:(this.searchData.params.time&&this.searchData.params.time[1])||'',
        orderNo:this.searchData.params.orderNo,
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
      }
    },

    // 查询列表
    searchList(){
      this.$api.tour.workOrderStatistic(this._getParams()).then(({data})=>{
        this.tableData = data.list;
        this.pager.count = data.total;
      })
    },

    // 导出
    exportList(){
      this.$api.tour.exportWorkOrderStatistic(this._getParams()).then(({headers,data})=>{
        onDownload(headers,data)
      })
    },

    showDetail(row){
      this.$router.push({path:'/tourModule/report/workorder/detail',query:{id:row.id,needRead:row.hasNewComment}})
    },

    reset(){
      this.pager.page = 1;
      this.pager.rows = 10;
      this.searchList()
    }
  }
}
</script>

<style scoped>

</style>